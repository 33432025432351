/* You can add global styles to this file, and also import other style files */
:root {
    //Color
    --thm-text-color: #323953;
    --thm-text-hover-color: #bca578;
    --thm-white-color: #fff;
    --thm-grey-text-hover-color: #333;
    --thm-grey-color: #707070;
    --thm-grey-color-1: #77767A;
    --thm-grey-color-2: #EAE8E6;
    --thm-grey-color-3: #686564;
    --thm-grey-color-4: #777777;
    --thm-grey-color-5: #edece6;
    --thm-grey-color-6: #dedada;
    --thm-grey-color-7: #e7e7e7;
    --thm-grey-color-8: #bcbcbc;
    --thm-grey-color-9: #686564;
    --thm-grey-color-10: #666666;
    --thm-grey-color-11: #333333;
    --thm-grey-color-12: #393939;
    --thm-grey-color-13: #6e6b6a;
    --thm-grey-color-14: #979797;
    --thm-grey-color-15: #7B7D7F;
    --thm-grey-color-16: #CBCECA;
    --thm-grey-border-color: #bababd;
    --thm-grey-background-color: #F7F9F6;
    --thm-grey-background-color-1: #F2F2F2; 
    --thm-grey-background-color-2: 247, 249, 246;
    --thm-gold-color: #8B7341;
    --thm-gold-color-1: #a99477;
    --thm-gold-color-2: #90783f;
    --thm-gold-color-3: #8f7743;
    --thm-gold-color-4: #8e754c;
    --thm-brown-color: #6b0707;
    --thm-blue-color: #0A2135;
    --thm-blue-color-1: #031F36;
    --thm-blue-color-2: #00264c;
    --thm-blue-color-3: #052640;
    --thm-blue-color-4: #092036;
    --thm-blue-color-5: #2f6cbd;
    --thm-blue-color-6: #272b2e;
    --thm-red-color: #d0011b;

    //z-index
    --loading-z-index: 100000000;
    --popup-z-index: 10000000;
    --fixed-z-index: 1000;
    --dropdown-z-index: 300;
    --message-bar-z-index: 200;
    --header-z-index: 100;
    --absolute-z-index: 10;
}

html,
body {
  padding: 0;
  margin: 0;
}

body.isPopup {
    overflow: hidden;
}

//Hide scroll bar
::-webkit-scrollbar {
    display: none;
}

.wrapper {
    overflow-x: hidden;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; color: var(--thm-text-color);}

h1 {
    font-size: 32px;
    line-height: 2;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    text-align: center;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
    margin-block-start: 0;
    margin-block-end: 0.5rem;
    font-weight: 400;

    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
}

h4 {
    font-size: 20px;
    margin-block-start: 0;
    margin-block-end: 1rem;
    font-weight: 400;
}

h5 {
    font-size: 16px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
}

p {
    font-size: 18px;
}

a, span, div, input {
    font-size: 14px;
}

a {
    cursor: pointer;
    color: var(--thm-text-color);
    text-decoration: none;
    transition: all 0.3s;
}

a:hover {
    color: var(--thm-text-hover-color);
}

th {
    font-size: 14px;
    color: var(--thm-grey-color-4);
}

td {
    font-size: 14px;
    color: var(--thm-grey-color-4);
}

//opacity css
.transparent {
    opacity: 0 !important;
}

//text decoration class
.italic {
    font-style: italic;
}

//container level
.page-container {
    min-height: 66vh;
}

.container-full-width {
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: visible;
}

.container-1920 {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: visible;
}

.container-1440 {
    max-width: 1440px;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: visible;
}

.container-1200 {
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: visible;
}

.container-1024 {
    max-width: 1024px;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: visible;
}

//width class
.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-48 {
    width: 48%;
}

.w-49 {
    width: 49%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-100 {
    width: 100%;
}

// padding class
.p-30 {
    padding: 30px;
}

//text-alignment class
.text-align-start {
    text-align: start;
}

.text-align-end {
    text-align: end;
}

//text-decoration class
.text-line-through {
    text-decoration: line-through;
}

//position class
.position-relative {
    position: relative;
}

//table row position relative hack
tr.position-relative {
    transform: scale(1);
}

//margin class
.mt-1 {
    margin-top: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

//display class
.d-none-m {
    @media screen and (max-width: 991px) {
        display: none !important;
    }
}

.d-none-d {
    @media screen and (min-width: 991px) {
        display: none !important;
    }
}

//snack bar css
.success-dialog {
    color: white !important;
    .mat-simple-snackbar-action {
        color: white !important;
    }
}